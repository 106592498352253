var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.isLoading ? _c('div', {
    staticClass: "d-flex justify-content-center mb-3"
  }, [_c('b-spinner', {
    attrs: {
      "label": "Loading..."
    }
  })], 1) : _c('div', [_c('div', {
    staticClass: "col-md-12"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-6 text-right pr-0"
  }, [_c('el-input', {
    attrs: {
      "placeholder": "Type product or supplier name",
      "prefix-icon": "el-icon-search"
    },
    model: {
      value: _vm.search,
      callback: function ($$v) {
        _vm.search = $$v;
      },
      expression: "search"
    }
  })], 1)])]), _c('el-table', {
    staticClass: "mt-4 w-100",
    attrs: {
      "data": _vm.tableDataBids,
      "lazy": "",
      "id": "table-compare-spec",
      "default-sort": {
        prop: 'requested_products',
        order: 'asscending'
      }
    }
  }, [_c('el-table-column', {
    attrs: {
      "v-show": true,
      "sortable": "",
      "fixed": "",
      "prop": "uuid",
      "label": this.CombinationUtil.headermap_bid.uuid,
      "width": "150"
    }
  }), _c('el-table-column', {
    attrs: {
      "v-show": true,
      "sortable": "",
      "fixed": "",
      "prop": "requested_products",
      "label": this.CombinationUtil.headermap_product.requested_products,
      "width": "300"
    }
  }), _c('el-table-column', {
    attrs: {
      "v-show": true,
      "sortable": "",
      "fixed": "",
      "prop": "organisation",
      "label": this.CombinationUtil.headermap_bid.organisation_name,
      "width": "190"
    }
  }), _c('el-table-column', {
    attrs: {
      "v-show": true,
      "sortable": "",
      "fixed": "",
      "prop": "product_name",
      "label": this.CombinationUtil.headermap_bid.trade_product_name,
      "width": "300"
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }